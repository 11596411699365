<template>
	<div class="pt-5">
		<div v-if="!noDataFound">
			<div v-if="isLoading" color="grey lighten-4" class="pa-3">
				<v-row>
					<v-col cols="12" v-for="i in 4" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
					</v-col>
				</v-row>
			</div>
			<v-row class="mx-0 px-4" v-if="reviews.length > 0">
				<v-col
					cols="12"
					class="pa-0"
					v-for="review in reviews"
					:key="review.reviewId"
				>
					<v-card class="pa-4">
						<v-row class="mx-0">
							<v-col cols="1" class="pa-0">
								<v-avatar>
									<img
										:src="review.reviewer.profilePhotoUrl"
									/>
								</v-avatar>
							</v-col>
							<v-col cols="11" class="pa-0 d-flex flex-column">
								<div
									class="
										d-flex
										flex-row
										justify-space-between
										mb-3
									"
								>
									<div class="d-flex flex-column">
										<p class="reviewer font-weight-medium">
											{{ review.reviewer.displayName }}
										</p>
										<p class="date">
											{{ dateFormat(review.updateTime) }}
										</p>
									</div>
									<div class="ratings">
										<v-rating
											color="yellow darken-3"
											background-color="grey"
											empty-icon="$ratingFull"
											dense
											length="5"
											readonly
											:value="ratings(review)"
											size="18"
										></v-rating>
									</div>
								</div>
							</v-col>
						</v-row>
						<p
							v-if="review.comment"
							:class="[
								'mb-3 ml-5 body-2 pl-10',
								review.reviewReply && review.reviewReply.comment
									? 'comment'
									: '',
							]"
						>
							{{ review.comment }}
						</p>
						<p
							:class="[
								'mb-3 ml-5 body-1 pl-10 font-italic ',
								review.reviewReply && review.reviewReply.comment
									? 'comment'
									: '',
							]"
							v-else
						>
							* The user didn't write a review, and has left just
							a rating.
						</p>
						<v-row class="mx-0" v-if="review.reviewReply">
							<v-col cols="1" class="pa-0">
								<v-avatar>
									<img
										src="https://www.gstatic.com/bfe/images/icons/default_merchant_avatar.svg"
									/>
								</v-avatar>
							</v-col>
							<v-col cols="11" class="pa-0 d-flex flex-column">
								<div class="d-flex flex-column mb-3">
									<p class="replier">(owner)</p>
									<!-- {{ selectedOrg.accountName }} -->
									<p class="date">
										{{
											dateFormat(
												review.reviewReply.updateTime
											)
										}}
									</p>
								</div>
								<p
									class="font-italic body-2"
									v-if="review.reviewReply.comment"
								>
									{{ review.reviewReply.comment }}
								</p>
							</v-col>
						</v-row>
						<v-card-actions class="mt-3">
							<div
								v-if="review.reviewReply"
								class="d-flex flex-row"
							>
								<UnoEditButton
									@click.native="replyDrawer(review)"
								></UnoEditButton>
								<UnoDeleteButton
									@confirm="deleteReply(review)"
									:deleting="deleting"
								></UnoDeleteButton>
							</div>
							<v-btn
								v-else
								outlined
								color="var(--blue)"
								@click="replyDrawer(review)"
								><span class="mdi mdi-reply"></span>
								<p>Reply</p>
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								v-if="review.comment"
								color="teal"
								:loading="testimonySaving"
								outlined
								@click="addToTestimony(review)"
								>Add to Testimony</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
			<div v-if="openReplyDrawer">
				<ReplyDrawer
					:review-data="review"
					@close-drawer="closeDrawer"
					@on-review-update="updateReview($event)"
					:saving="isSaving"
					:gmb-location="locationName"
				></ReplyDrawer>
			</div>
			<v-dialog v-model="testimonyDialog" persistent max-width="450">
				<v-card class="mb-0">
					<v-card-title class="headline" v-if="!testimonyError">
						Testimony Added Sucessfully.
					</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="green darken-1"
							text
							@click="resetTestimony()"
						>
							close
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<div class="d-flex flex-row justify-center ma-2">
				<v-btn
					@click="getReviews(previousPageToken, true, false)"
					:disabled="!previousPageToken"
					:loading="btnLoading"
					class="mr-2"
				>
					<span class="mdi mdi-arrow-left-bold"></span
				></v-btn>
				<v-btn
					@click="getReviews(nextPageToken, false, true)"
					:disabled="!nextPageToken"
					:loading="btnLoading"
					><span class="mdi mdi-arrow-right-bold"></span
				></v-btn>
			</div>
		</div>
		<NoData v-else></NoData>
	</div>
</template>
<script>
import NoData from '../../../components/NoData'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import ReplyDrawer from './ReviewReplyDrawer'
export default {
	components: {
		UnoEditButton,
		ReplyDrawer,
		UnoDeleteButton,
		NoData,
	},
	data() {
		return {
			btnLoading: false,
			page: 1,
			repeat: false,
			pageTokens: [],
			nextPageToken: null,
			previousPageToken: null,
			openReplyDrawer: false,
			review: {},
			reviews: [],
			isLoading: false,
			deleting: false,
			isSaving: false,
			testimonySaving: false,
			testimonyDialog: false,
			testimonyError: false,
			noDataFound: false,
		}
	},
	created() {
		this.getReviews()
	},
	computed: {
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	methods: {
		resetTestimony() {
			this.testimonyDialog = false
			this.testimonyError = false
		},
		addToTestimony(review) {
			const projectId = this.$store.state.app.projectId
			if (!review.comment) {
				this.testimonyDialog = true
				this.testimonyError = true
				return
			}
			let payload = {
				givenBy: review.reviewer.displayName,
				anonimity: false,
				type: 'GMB',
				date: review.createTime,
				body: review.comment,
				email: this.locationEmail,
			}
			this.testimonySaving = true
			this.axios({
				method: 'post',
				url: `/${projectId}/testimonies`,
				data: payload,
			}).then(() => {
				this.testimonySaving = false
				this.testimonyDialog = true
			})
		},
		ratings(review) {
			let rating
			switch (review.starRating) {
				case 'FIVE':
					rating = 5
					break
				case 'FOUR':
					rating = 4
					break
				case 'THREE':
					rating = 3
					break
				case 'TWO':
					rating = 2
					break
				case 'ONE':
					rating = 1
					break

				default:
					break
			}
			return rating
		},
		replyDrawer(reviewData) {
			this.openReplyDrawer = true
			this.review = reviewData
		},
		closeDrawer() {
			this.openReplyDrawer = !this.openReplyDrawer
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		getReviews(nextPageToken, previous, next) {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.btnLoading = true
			let payload = {
				name: this.locationName,
				email: this.locationEmail,
				pageToken: nextPageToken ? nextPageToken : null,
			}
			if (nextPageToken === 'last') payload.pageToken = null
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/reviews`,
				data: payload,
			})
				.then((response) => {
					this.isLoading = false
					this.$nextTick(function () {
						this.reviews = response.data.data.reviews
						this.reviews
							? (this.noDataFound = false)
							: (this.noDataFound = true)
					})
					if (next) {
						response.data.data.nextPageToken
							? this.pageTokens.push(
									response.data.data.nextPageToken
							  )
							: null
						this.previousPageToken =
							this.pageTokens.length <= 1
								? null
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.nextPageToken = response.data.data.nextPageToken
						? response.data.data.nextPageToken
						: null
					if (previous && this.previousPageToken) {
						this.pageTokens.splice(this.pageTokens.length - 1, 1)
					}
					this.previousPageToken =
						this.pageTokens.length === 0
							? null
							: this.pageTokens[this.pageTokens.length - 2]
					if (this.repeat) {
						this.previousPageToken =
							this.pageTokens.length === 1
								? 'last'
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.repeat = true
					this.btnLoading = false
				})
				.catch((err) => {
					if (err.response.status == 404) this.noDataFound = true
					this.isLoading = false
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		updateReview(reviewObject) {
			this.isSaving = true
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/review/reply`,
				data: {
					...reviewObject,
					email: this.locationEmail,
				},
			})
				.then((response) => {
					this.isSaving = false
					this.openReplyDrawer = false
					if (response?.data) {
						this.$snackbar.notify({
							message: response?.data?.message,
							color: 'green',
						})
						setTimeout(() => {
							this.$snackbar.notify({
								message:
									'Reviews will be updated after a while.',
							})
						}, 5000)
						setTimeout(() => {
							this.getReviews()
						}, 20000)
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err?.response?.data?.message,
						color: 'error',
					})
				})
		},
		deleteReply(reviewObject) {
			this.deleting = true
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/review/reply/delete`,
				data: {
					name: reviewObject.name,
					email: this.locationEmail,
				},
			})
				.then((response) => {
					this.deleting = false
					this.$snackbar.notify({
						message: response?.data?.message,
						color: 'yellow',
					})
					setTimeout(() => {
						this.$snackbar.notify({
							message: 'Reviews will be updated after a while.',
						})
					}, 5000)
					if (response?.data) {
						setTimeout(() => {
							this.getReviews()
						}, 20000)
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
	},
}
</script>
<style scoped>
.reviewer,
.replier {
	font-size: 16px;
}
.date {
	font-size: 14px;
}
.comment {
	border-left: 2px solid #dadce0;
}
.mdi-reply {
	font-size: 24px;
}
</style>
