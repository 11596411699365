<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="review-drawer"
	>
		<div class="d-flex align-center header pt-4 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="closeDrawer()"
			></span>
			<h1 class="crud-title pl-2">Review Reply</h1>
		</div>
		<v-row class="pa-4 mx-0 mb-8">
			<v-col cols="1" class="pa-0">
				<v-avatar>
					<img :src="reviewData.reviewer.profilePhotoUrl" />
				</v-avatar>
			</v-col>
			<v-col cols="11" class="pa-0 pl-4 d-flex flex-column">
				<div class="d-flex flex-row justify-space-between mb-3">
					<div class="d-flex flex-column">
						<p class="reviewer font-weight-bold">
							{{ reviewData.reviewer.displayName }}
						</p>
						<p class="date">
							{{ dateFormat(reviewData.updateTime) }}
						</p>
					</div>
					<div class="ratings">
						<v-rating
							color="yellow darken-3"
							background-color="grey"
							empty-icon="$ratingFull"
							dense
							half-increments
							length="5"
							readonly
							:value="ratings()"
							size="18"
						></v-rating>
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="pa-0">
				<p class="mb-8 body-2 comment">
					{{ reviewData.comment }}
				</p>
				<p class="mb-2">Write Your Reply</p>
				<v-textarea
					name="address"
					no-resize
					outlined
					dense
					@input="$v.reply.$touch()"
					@blur="$v.reply.$touch()"
					:error-messages="replyErrors()"
					auto-grow
					v-model="reply"
					hint="Please note that your reply will be displayed publicly on Google and must comply with Google's local content policies. Terms of service"
					persistent-hint
				></v-textarea>
				<div v-if="suggestions.length > 0">
					<p>Suggestions</p>
					<v-chip-group active-class="primary--text" column>
						<v-chip
							v-for="suggestion in suggestions"
							:key="suggestion.id"
							@click="
								suggestionSelected({
									id: suggestion.id,
									template: suggestion.template,
								})
							"
						>
							{{ suggestion.template }}
						</v-chip>
					</v-chip-group>
				</div>
			</v-col>
		</v-row>
		<div class="d-flex flex-row justify-end footer">
			<v-btn
				@click="saveReview()"
				depressed
				class="save"
				:loading="saving"
				>Save</v-btn
			>
		</div>
	</v-navigation-drawer>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
export default {
	data() {
		return {
			reply: '',
			drawer: true,
			suggestions: [],
			templateId: null,
			haveErrors: false,
		}
	},
	props: {
		reviewData: {
			type: Object,
			required: true,
		},
		saving: {
			type: Boolean,
			default: false,
		},
		gmbLocation: {
			type: String,
			required: true,
		},
	},
	created() {
		this.reply = this.reviewData.reviewReply
			? this.reviewData.reviewReply.comment
			: ''
		this.getSuggestions()
	},
	validations: {
		reply: {
			required,
			minLength: minLength(5),
		},
	},
	methods: {
		replyErrors() {
			let errors = []
			if (!this.$v.reply.$dirty) return errors

			!this.$v.reply.minLength &&
				errors.push('Reply must be atleast 5 letters long')
			!this.$v.reply.required && errors.push('Reply is required.')
			if (errors.length > 0) this.haveErrors = true
			else this.haveErrors = false
			return errors
		},
		ratings() {
			let rating
			switch (this.reviewData.starRating) {
				case 'FIVE':
					rating = 5
					break
				case 'FOUR':
					rating = 4
					break
				case 'THREE':
					rating = 3
					break
				case 'TWO':
					rating = 2
					break
				case 'ONE':
					rating = 1
					break
				default:
					break
			}
			return rating
		},
		suggestionSelected(suggestion) {
			this.reply = suggestion.template
			this.templateId = suggestion.id
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				this.media = val
			} else {
				this.media = []
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		closeDrawer() {
			this.$emit('close-drawer')
		},
		saveReview() {
			if (!this.haveErrors) {
				let payload = {
					reply: this.reply,
					name: this.reviewData.name,
					templateId: this.templateId,
				}
				this.$emit('on-review-update', payload)
			}
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		getSuggestions() {
			const projectId = this.$store.state.app.projectId
			let gmbLocation = this.gmbLocation
			this.axios({
				method: 'post',
				url: `/${projectId}/templates/reply`,
				data: { gmbLocation: gmbLocation },
			})
				.then((response) => {
					this.suggestions = response.data.data
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
	},
}
</script>
<style scoped>
.review-drawer {
	width: 40% !important;
	height: 100% !important;
	overflow-y: auto;
}
.review-drawer .header {
	border-bottom: 1px solid #eee;
}
.review-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.review-drawer .crud-title {
	width: 100%;
}
.review-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.footer {
	background-color: var(--blue);
	height: 47px;
	bottom: 0;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
